<script>
  import { defineComponent } from "@vue/composition-api";

  export default defineComponent({
    setup() {}
  });
</script>

<template>
  <div>This is the Account Settings Page for the current auth user</div>
</template>
